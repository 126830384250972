import "./styles/global.module.css";
import { ApolloProvider } from '@apollo/client'
import { AppProvider } from './src/components/context'
import { AuthProvider } from "./src/components/useAuth"
import React from 'react'
import { client } from './lib/apolloClient'

// import { wrapPageElement as wrap } from "./wrapper";
import "./styles/global.module.css"
// export const wrapPageElement = wrap;

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <AppProvider>{element}</AppProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}

export const onRenderBody = ({ setHeadComponents, pathname }) => {
  const siteUrl = "https://www.doctormedica.co";
  const canonicalUrl = `${siteUrl}${pathname.split("?")[0]}`;

  setHeadComponents([
    <link key="canonical" rel="canonical" href={canonicalUrl} />,
  ]);
};