import React, {
	Dispatch,
	SetStateAction,
	createContext,
	useContext,
	useState,
} from 'react';

import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from './useAuth';
import { Cart } from '../../types/Cart';
import { client } from '../../lib/apolloClient';

const defaultState: AppState = {
	setCart: () => {},
	cart: undefined,
};

const AppContext = createContext(defaultState);

const AppProvider: React.FC<ContextProps> = ({ children }) => {
	const [cart, setCart] = useState<AppState['cart']>(defaultState.cart);

	return (
		<ApolloProvider client={client}>
			{/* <AuthProvider> */}
			<AppContext.Provider value={{ cart, setCart }}>
				{children}
			</AppContext.Provider>
			{/* </AuthProvider> */}
		</ApolloProvider>
	);
};

const useAppState = () => useContext(AppContext);

type AppState = {
	cart?: Cart;
	setCart: Dispatch<SetStateAction<Cart | undefined>>;
};

type ContextProps = {
	children: React.ReactNode;
};

export { AppProvider, useAppState };
